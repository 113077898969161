<template>
    <div class="col-md-6">
        <seccion-datos :titulo="documentacionrequerida" :botonGuardar="false" class="card-success">
            <div class="row">
                <div class="col-md-8">
                    <div class="input-group">
                        <select class="custom-select form-control-sm select2" v-model="seleccionado">
                            <option v-for="documento in documentaciones" :key="documento.id_documentacion" :value="documento.id_documentacion">{{documento.nombre_documentacion}} 
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-1">
                    <button class="btn btn-primary" @click="añadirdocumentacion(seleccionado)">{{ $t('general.anadir') }}</button>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-8">
                    <table class="table table-sm table-striped">
                        <tbody>
                            <tr v-for="docs in operario_docum" :key="docs.id">
                                <td> {{ docs.nombre }}</td>
                                <td><button type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminar(oper.idcompañia)">{{ $t('general.eliminar') }}</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </seccion-datos>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'operario'],
    data() {
        return {
            documentaciones: [],
            seleccionado: '',
            operario_docum: '',
            documentacionrequerida:"Documentación requerida",
        }
    },
    methods: {
        async cargarDatosDocumentos() {
            /* GET  modulos/pwgsapi/index.php/documentacion-requerida */
            const api = new PwgsApi;
            const datos = await api.get('documentacion-requerida');
            this.documentaciones = datos.datos;
            this.documentacionrequerida = this.$t("general.documentacionrequerida");
        },
        async cargarDatosOperario() {
            /* GET  modulos/pwgsapi/index.php/operarios/:id/documentacion-requerida/:id_documentacion */
            const api = new PwgsApi;
            const datos = await api.get('operarios/' + this.id + '/companias');
            this.operario_compañia = datos;
        },
        async eliminar(idcompañia) {
            /* DELETE  modulos/pwgsapi/index.php/operarios/:id/companias/:id_compania */
            const api = new PwgsApi;
            await api.delete('operarios/' + this.id + '/companias/' + idcompañia);
            this.cargarDatosOperario();
        },
        async añadirdocumentacion(id) {
            /* POST  modulos/pwgsapi/index.php/operarios/:id/companias */
            const api = new PwgsApi;
            await api.post('operarios/' + this.operario.id + '/companias', id);
            this.cargarDatosOperario();
        }
    },
    watch: {
        id() {
            this.cargarDatosDocumentos();
            this.cargarDatosOperario();
        }
    },
    mounted() {
        this.cargarDatosDocumentos();
        this.cargarDatosOperario();
    }
}
</script>